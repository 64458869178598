import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElIcons from '@element-plus/icons-vue'
import VueSocketIO from 'vue-socket-kevin.io';
import pinia from './stores'
import 'animate.css';


// 全局挂载
const app = createApp(App)

const socket = new VueSocketIO({
    connection: 'http://api.zftdiy.com:8910',
});
app.use(socket)

for (const name in ElIcons){
    app.component(name, ElIcons[name])
}

app.use(pinia)
app.use(ElementPlus,{locale})
app.use(router)
app.mount('#app')

